import React,{} from 'react';
import { NavLink } from 'react-router-dom';

function Facts() {
  return (
    <>
        <div>
            {/* Facts Start */}
            <div className="container-fluid overflow-hidden my-5 px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 facts-text wow fadeIn" data-wow-delay="0.1s">
                        <div className="h-100 px-4 ps-lg-0">
                            <h1 className="text-white mb-4">For Individuals And Organisations</h1>
                            <p className="text-light mb-5">
                            At Sync Insurance Agency, we understand the critical role insurance plays in safeguarding your personal and professional well-being.  We offer a meticulously curated selection of insurance products from all major Kenyan insurance providers, ensuring you have access to the most comprehensive coverage options available.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 facts-counter wow fadeIn" data-wow-delay="0.5s">
                        <div className="h-100 px-4 pe-lg-0">
                        <div className="row g-5">
                            <div className="col-sm-6">
                            <h1 className="display-5" data-toggle="counter-up">100+</h1>
                            <p className="fs-5 text-primary">Satisfied Clients</p>
                            </div>
                            <div className="col-sm-6">
                            <h1 className="display-5" data-toggle="counter-up">300+</h1>
                            <p className="fs-5 text-primary">Policies Issued</p>
                            </div>
                            <div className="col-sm-6">
                            <h1 className="display-5" data-toggle="counter-up">150+</h1>
                            <p className="fs-5 text-primary">Claims Processed</p>
                            </div>
                            <div className="col-sm-6">
                            <h1 className="display-5" data-toggle="counter-up">100%</h1>
                            <p className="fs-5 text-primary">Satisfaction Rating</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {/* Facts End */}
        </div>
    </>
  );
}

export default Facts;