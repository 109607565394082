import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function PersonalAccidentInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">PERSONAL ACCIDENT INSURANCE</h3>
                    <p className='text-dark'>Personal Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-7.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>shield-account-outline</title><path d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,3.18L19,6.3V11.22C19,12.92 18.5,14.65 17.65,16.17C16,14.94 13.26,14.5 12,14.5C10.74,14.5 8,14.94 6.35,16.17C5.5,14.65 5,12.92 5,11.22V6.3L12,3.18M12,6A3.5,3.5 0 0,0 8.5,9.5A3.5,3.5 0 0,0 12,13A3.5,3.5 0 0,0 15.5,9.5A3.5,3.5 0 0,0 12,6M12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8M12,16.5C13.57,16.5 15.64,17.11 16.53,17.84C15.29,19.38 13.7,20.55 12,21C10.3,20.55 8.71,19.38 7.47,17.84C8.37,17.11 10.43,16.5 12,16.5Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">PERFECT PERSONAL ACCIDENT INSURANCE SOLUTION</h1>
                                <p>Personal Accident Insurance offers complete financial protection against accidental death and accidental physical injuries. The personal insurance covers permanent disability, temporary total disablement, medical expenses, hospital confinement allowance, funeral expenses, and offers life support benefits. Being the best personal accident insurance Kenyan company, we provide affordable solutions to provide family security and enhance the overall well-being of the customers. We offer 24/7 support service and customizable coverage at a lower premium.</p>
                                <p className="fs-5 text-primary mb-4">Key Benefits Of Personal Accident Insurance</p>
                                <ul>
                                    <li>Provides coverage for policyholder’s employment loss</li>
                                    <li>Easy policy renewal through online</li>
                                    <li>Coverage for loss of eyesight, limbs, and in case of permanent partial or temporary total disablement</li>
                                    <li>Insurance for children’s education cost</li>
                                    <li>Support for ambulance cost</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <h1 className="text-white text-center mb-0">Why Do You Need Personal Accident Insurance?</h1>
                                <div className='d-flex justify-content-center my-2 mb-0'><hr className='w-25 my-5'/></div>
                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Support for Physical Disability</h5>
                                        <p className="text-light mb-4">Due to a sudden accident, we have seen that many people become temporarily or permanently disabled. The disabilities are of various types. It can be disabilities of hand, foot, or both hands or feet, or can be the loss of eyesight, loss of hearing, etc. Dealing with these unexpected worst situations is difficult and financial support for the treatments and medical expenses is much needed. Our personal accident insurance in Kenya offers necessary aid if the policyholder approaches the insurer with a claim and will receive compensation for the loss.</p>
                                    
                                        <h5 className="fw-bold text-secondary mb-2">Home Alteration and Vehicle Modification Benefits</h5>
                                        <p className="text-light mb-4">If you have suffered a loss and are using a wheelchair for transportation, and in case of suitable modifications, the policyholder can ask the insurer to compensate you for the changes. This financial support can be facilitated through a personal accident insurance claim. Being a top personal accident insurance in Kenya, we offer personal insurance plans that provide support and assistance under any conditions.</p>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Important Hospital and Treatment Expense</h5>
                                        <p className="text-light mb-4">Personal insurance helps you to deal with the major hospital expenses and other treatment expenses. It includes hospital bills, medical treatment charges, and other miscellaneous expenses. In the case of policyholder demise, the family gets the financial support to cover the repatriation charges, ambulance charges, and funeral expenses.</p>
                                    
                                        <h5 className="fw-bold text-secondary mb-2">Death of the Insured person</h5>
                                        <p className="text-light mb-4">Under this situation, the compensation will be disbursed to the family. If the policyholder has children, the policy offers support for their education. The insurer pays 10 % of the insured amount or the institution’s real tuition fee, whichever is lower.</p>
                                        <h5 className="fw-bold text-secondary mb-2">Family Transportation Costs</h5>
                                        <p className="text-light mb-4">If the policyholder is hospitalized more than 150 km from the home, then the personal accident insurance helps your family and all transportation costs will be reimbursed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gradient-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto" style={{maxWidth: 800}}>
                            <h2 className="display-6 mb-3">Personal Accident Insurance in Kenya</h2>
                            <p className='mb-4'>Personal Accident Insurance is a policy to cover individuals and groups from the physical injuries and financial loss. It covers among other things death, Permanent Disability, Temporary Total Disablement, accidental medical expenses, funeral expenses, hospital cash, artificial appliances.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded bg-white text-center h-100 p-5">
                                    <p className="mb-3">This type of cover is a hybrid of two products, life insurance and medical insurance as it caters for bodily injuries and also covers pure financial indemnity to the insured in form of death and disability benefits. Insurance premiums for PA insurance policies have duration of one year and insurance premiums are paid annually.</p>
                                    <p className="mb-0">It is mandatory to have a Personal Accident Insurance coverage as it provides financial coverage if you are met with a sudden accident where bodily injuries or total/partial disability or death is occurred because of accidents. Sync Insurance Agency is the best insurance company in Kenya offering you the right Personal accident insurance policies. Personal accident insurance will protect you and your family members in case of emergency situations offering hospital charges, financial security, etc. incase of accidental risks.</p>
                                <div className='row justify-content-center mt-5'>
                                    <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default PersonalAccidentInsurance;