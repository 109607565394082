import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function ProfessionalIndemnityInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Professional Indemnity Insurance</h3>
                    <p className='text-dark'>Business Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-6.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>briefcase-account-outline</title><path d="M20,6C20.58,6 21.05,6.2 21.42,6.59C21.8,7 22,7.45 22,8V19C22,19.55 21.8,20 21.42,20.41C21.05,20.8 20.58,21 20,21H4C3.42,21 2.95,20.8 2.58,20.41C2.2,20 2,19.55 2,19V8C2,7.45 2.2,7 2.58,6.59C2.95,6.2 3.42,6 4,6H8V4C8,3.42 8.2,2.95 8.58,2.58C8.95,2.2 9.42,2 10,2H14C14.58,2 15.05,2.2 15.42,2.58C15.8,2.95 16,3.42 16,4V6H20M4,8V19H20V8H4M14,6V4H10V6H14M12,9A2.25,2.25 0 0,1 14.25,11.25C14.25,12.5 13.24,13.5 12,13.5A2.25,2.25 0 0,1 9.75,11.25C9.75,10 10.76,9 12,9M16.5,18H7.5V16.88C7.5,15.63 9.5,14.63 12,14.63C14.5,14.63 16.5,15.63 16.5,16.88V18Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">How does Professional Indemnity Insurance work?</h1>
                                <p>Professional Indemnity Insurance is the insurance for the organizations that give professional consultation services to their clients. If any of their clients sued them for the financial loss caused due to failure of services or bad advice, the professional indemnity insurance helps those organizations to cover the claim. Being the best professional indemnity insurance Kenyan company, we support every company in all verticals such as healthcare, architecture, financial advisors, etc. </p>
                                <p>We have specially customized it for different professionals and their services. It is recommended to take professional indemnity insurance cover to prevent the huge loss of money due to a slight mistake or error that will adversely affect the balance of your business.</p>
                                <p className="fs-5 text-primary mb-4">Why Sync Insurance Professional Indemnity Insurance?</p>
                                <p>We are the top professional indemnity insurance Kenyan company focussing on protecting the businesses and organizations from claims raised by their clients due to negligence, errors or malpractice. If an accident occurs before the activation of the professional indemnity insurance, that claim might not be covered by the company.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden my-5 px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 facts-text facts-text-professional wow fadeIn" data-wow-delay="0.1s">
                            <div className="h-100 px-4 ps-lg-0">
                                <h1 className="text-white mb-4">Benefits of Professional Indemnity Insurance</h1>
                                <h5 className='text-secondary mb-3 d-flex'><span className="fa fa-check fs-18 me-2"></span>Tailored professional indemnity covers for the needs of different professionals and professional services</h5>
                                <h5 className='text-secondary mb-3 d-flex'><span className="fa fa-check fs-18 me-2"></span>Exclusive coverage against different types of risks</h5>
                                <h5 className='text-secondary mb-3 d-flex'><span className="fa fa-check fs-18 me-2"></span>Professional indemnity insurance for legal expenses</h5>
                                <h5 className='text-secondary mb-3 d-flex'><span className="fa fa-check fs-18 me-2"></span>Easy and hassle-free documentation</h5>
                                <h5 className='text-secondary mb-0 d-flex'><span className="fa fa-check fs-18 me-2"></span>Mid-term changes in the amount insured are allowed</h5>
                            </div>
                        </div>
                        <div className="col-lg-6 facts-counter facts-counter-professional wow fadeIn" data-wow-delay="0.5s">
                            <div className="h-100 px-4 pe-lg-0">
                                <div className="row g-2">
                                    <div className="col-sm-12">
                                        <h1 className="fw-bold text-primary mb-4">What does Professional indemnity insurance cover?</h1>
                                        <p className="text-dark mb-2">Being the best professional indemnity Kenyan company, we offer the best insurance policies that cover errors, mistakes, and or omissions. Professional Indemnity is provided as per the conditions and limits mentioned in the policy. The scopes of the cover are:</p>
                                        <ul className='text-dark'>
                                            <li>Charges for the legal defense</li>
                                            <li>Judgments/awards in the civil court</li>
                                            <li>Fees and expenses incurred during the lawsuit</li>
                                            <li>Professional indemnity for the charges due to the loss of documents</li>
                                            <li>Dishonesty of employees</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h3 className="display-6 mb-5">How much does Professional indemnity Cost?</h3>
                            <p className='mb-4'>The cost of the professional indemnity insurance is not fixed and it varies based on the industries. The factors such as the type of business, policy coverage, location of business, risks factors, business credit score, etc. affects the cost of the professional indemnity insurance.</p>
                            <div className='row justify-content-start'>
                                <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded h-100 p-5">
                                    <h3 className="mb-4">Who are the professionals?</h3>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <ul>
                                                <li>Dentists</li>
                                                <li>IT Companies</li>
                                                <li>Radiologists</li>
                                                <li>Dermatologists</li>
                                                <li>Gynaecologists</li>
                                                <li>ENT Surgeons</li>
                                                <li>Cardiologists</li>
                                            </ul>
                                        </div>
                                        <div className='col-md-6'>
                                            <ul>
                                                <li>Paediatricians</li>
                                                <li>Pathologists</li>
                                                <li>Transportation providers</li>
                                                <li>Food & Beverage</li>
                                                <li>Entrepreneurs</li>
                                                <li>General Practitioners</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </>
  );
}

export default ProfessionalIndemnityInsurance;