import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function BurglaryInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Burglary Insurance</h3>
                    <p className='text-dark'>Business Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-burglary-insurance.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>shield-lock-outline</title><path d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M14.8,11V9.5C14.8,8.1 13.4,7 12,7C10.6,7 9.2,8.1 9.2,9.5V11C8.6,11 8,11.6 8,12.2V15.7C8,16.4 8.6,17 9.2,17H14.7C15.4,17 16,16.4 16,15.8V12.3C16,11.6 15.4,11 14.8,11M13.5,11H10.5V9.5C10.5,8.7 11.2,8.2 12,8.2C12.8,8.2 13.5,8.7 13.5,9.5V11Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">Best Burglary Insurance Policy in Kenya</h1>
                                <p>Burglary insurance is an important policy to protect your home, property, and assets against the losses and damages that can be caused due by a potential burglary. Consider a situation where all your years of hard work and money were lost rapidly taking away your peace of mind. To deal with those unforeseen burglary situations, good burglary insurance is much recommended. Sync Insurance offers the best-customized burglary and housebreaking policy features over all types of insured property such as house, property, stock in trade, furniture, fixture, money in lockers, etc.</p>
                                <p className="fs-5 text-primary mb-4">Key benefits of having Sync Insurance burglary insurance</p>
                                <ul>
                                    <li>Full coverage for burglary and housebreaking at your business premises</li>
                                    <li>Burglary policy can be extended to cover riot, strike, malicious damage, and theft.</li>
                                    <li>Other variations such as floater policy, declaration policy, and floater declaration policy are available.​</li>
                                    <li>Coverage for property from any damage due to fire, flood, storms, earthquake and aircraft explosion</li>
                                    <li>We offer the best possible affordable burglary insurance</li>
                                    <li>Special plan for renters</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <div className='px-5'>
                                    <h1 className="text-white text-center mb-4">Types of Burglary Insurance Sync Insurance Offer</h1>
                                    <p className='text-white text-center'>Being the best burglary insurance company in Kenya, we offer complete coverage for properties from theft, burglary, natural calamities and fire. The major burglary insurances we offer are:</p>
                                </div>
                                <div className='d-flex justify-content-center my-2 mb-4'><hr className='w-25'/></div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Burglary Insurance for your Home</h5>
                                            <p className="text-light">It is common that residential buildings and independent houses are more prone to thefts and burglaries. Thus it is mandatory to have burglary insurance for your house and we offer affordable burglary insurance policies that suit your specific requirements.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <div className="mb-2">
                                        <h5 className="fw-bold text-secondary mb-1">Burglary Insurance for your Businesses, enterprises and Shops</h5>
                                            <p className="text-white mb-0">To avoid the risk of burglary in your office or shops, it is important to take burglary insurance policies for your businesses also. It saves your assets and protects you from sudden financial crippling.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h2 className="mb-4">Who needs Burglary Insurance?</h2>
                                <ul className='mb-4'>
                                    <li>Residential buildings and houses</li>
                                    <li>Renters</li>
                                    <li>Small Enterprises</li>
                                    <li>Large Enterprises</li>
                                    <li>Medium Business Owners</li>
                                </ul>
                            <div className='row justify-content-start mt-5'>
                                <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded h-100 p-5">
                                    <h2 className="mb-4">Key factors to be considered when filing a burglary insurance claim</h2>
                                    <ul>
                                        <li>Take accurate note of all losses due to a burglary at your premise</li>
                                        <li>Use digital record and documents while making a claim as it reduces the possibility of tampering with the evidence</li>
                                        <li>Keep all the receipts of every expense and contract documents</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </>
  );
}

export default BurglaryInsurance;