import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

const Hero= ({scrollToAbout,scrollToServices})=> {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 400,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);
  return (
    <>
        <div>
            {/* Carousel Start */}
            <Element name='hero' className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src="img/carousel-1.jpg" alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h1 className="display-3 text-primary mb-4 animated slideInDown">Your Trusted Partner for All Your Insurance Needs</h1>
                                            <p className="fs-5 text-dark mb-5">At Sync Insurance Agency, we understand that every individual, business, and corporation has unique insurance requirements. </p>
                                            <NavLink to="/#about" onClick={scrollToAbout} className="btn btn-primary py-3 px-5">Learn More</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src="img/carousel-2.jpg" alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h1 className="display-3 text-primary mb-4 animated slideInDown">We've Got You Covered - Personal, Business & Corporate</h1>
                                            <p className="fs-5 text-dark mb-5">Sync Insurance Agency offers a comprehensive range of insurance solutions for individuals, businesses, and corporations.</p>
                                            <NavLink to="/#services" onClick={scrollToServices} className="btn btn-primary py-3 px-5">Our Services</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src="img/carousel-3.jpg" alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h1 className="display-3 text-primary mb-4 animated slideInDown">Peace of Mind for Your Family</h1>
                                            <p className="fs-5 text-dark mb-5">Protect your loved ones and your possessions with our personal insurance options like home, auto, and life insurance.</p>
                                            <NavLink to="/#services" onClick={scrollToServices} className="btn btn-primary py-3 px-5">Our Services</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </Element>
        </div>
    </>
  );
}

export default Hero;