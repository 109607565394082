import React,{} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Testimonials() {
    const options = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 1,
                nav: false
            },
            1000: {
                items: 1,
                nav: true,
                margin: 20
            }
        },
        autoplay: true, // Add this line for autoplay
        autoplayTimeout: 8000,
        slideBy: 1,
        autoplaySpeed: 800
    };
        
  return (
    <>
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto" style={{maxWidth: 500}}>
                    <h1 className="display-6 mb-5">What They Say About Our Insurance</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-3 d-none d-lg-block">
                            <div className="testimonial-left h-100">
                            <img className="img-fluid animated pulse infinite" src="img/testimonial-1.jpg" alt />
                            <img className="img-fluid animated pulse infinite" src="img/testimonial-2.jpg" alt />
                            <img className="img-fluid animated pulse infinite" src="img/testimonial-3.jpg" alt />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <OwlCarousel className="owl-theme" {...options}>
                                <div className="item testimonial-item text-center">
                                    <p className="fs-5">I've been with Sync Insurance Agency for years, and they've always been there for me. They helped me find affordable car insurance when I first started driving, and now they've provided me with comprehensive coverage for my growing family. They offer excellent customer service and always go the extra mile.</p>
                                    <h5>Sarah Wanjiku</h5>
                                    <span>Loyal Client, Nakuru</span>
                                </div>
                                <div className="item testimonial-item text-center">
                                    <p className="fs-5">Since opening my small shop in Eldoret, I knew I needed proper insurance. Sync Insurance Agency helped me understand the different types of business coverage available and found a policy that fit my budget perfectly. Their competitive rates and helpful advice have been invaluable.</p>
                                    <h5>Johnstone Otieno</h5>
                                    <span>Shop Owner, Eldoret</span>
                                </div>
                                <div className="item testimonial-item text-center">
                                    <p className="fs-5">As a busy entrepreneur in Nairobi, I don't have time to wade through complicated insurance policies. Sync Insurance Agency took the stress out of the process. Their agent, John, listened to my needs and found the perfect business insurance package for my company. Now I can focus on growing my business with peace of mind knowing I'm protected.</p>
                                    <h5>Aisha Mohammed</h5>
                                    <span>Entrepreneur, Nairobi</span>
                                </div>
                            </OwlCarousel>
                            {/* <div className="owl-carousel testimonial-carousel">
                                
                                
                                
                            </div> */}
                        </div>
                        <div className="col-lg-3 d-none d-lg-block">
                            <div className="testimonial-right h-100">
                                <img className="img-fluid animated pulse infinite" src="img/testimonial-5.jpg" alt />
                                <img className="img-fluid animated pulse infinite" src="img/testimonial-4.jpg" alt />
                                <img className="img-fluid animated pulse infinite" src="img/testimonial-6.jpg" alt />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
  );
}

export default Testimonials;