import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function GroupLifeInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Group Life Insurance</h3>
                    <p className='text-dark'>Business Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-group-insurance.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>account-group-outline</title><path d="M12,5A3.5,3.5 0 0,0 8.5,8.5A3.5,3.5 0 0,0 12,12A3.5,3.5 0 0,0 15.5,8.5A3.5,3.5 0 0,0 12,5M12,7A1.5,1.5 0 0,1 13.5,8.5A1.5,1.5 0 0,1 12,10A1.5,1.5 0 0,1 10.5,8.5A1.5,1.5 0 0,1 12,7M5.5,8A2.5,2.5 0 0,0 3,10.5C3,11.44 3.53,12.25 4.29,12.68C4.65,12.88 5.06,13 5.5,13C5.94,13 6.35,12.88 6.71,12.68C7.08,12.47 7.39,12.17 7.62,11.81C6.89,10.86 6.5,9.7 6.5,8.5C6.5,8.41 6.5,8.31 6.5,8.22C6.2,8.08 5.86,8 5.5,8M18.5,8C18.14,8 17.8,8.08 17.5,8.22C17.5,8.31 17.5,8.41 17.5,8.5C17.5,9.7 17.11,10.86 16.38,11.81C16.5,12 16.63,12.15 16.78,12.3C16.94,12.45 17.1,12.58 17.29,12.68C17.65,12.88 18.06,13 18.5,13C18.94,13 19.35,12.88 19.71,12.68C20.47,12.25 21,11.44 21,10.5A2.5,2.5 0 0,0 18.5,8M12,14C9.66,14 5,15.17 5,17.5V19H19V17.5C19,15.17 14.34,14 12,14M4.71,14.55C2.78,14.78 0,15.76 0,17.5V19H3V17.07C3,16.06 3.69,15.22 4.71,14.55M19.29,14.55C20.31,15.22 21,16.06 21,17.07V19H24V17.5C24,15.76 21.22,14.78 19.29,14.55M12,16C13.53,16 15.24,16.5 16.23,17H7.77C8.76,16.5 10.47,16 12,16Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">Best Life Insurance Company in Kenya to Resolve Unexpected Problems</h1>
                                <p>Sync Insurance is the best Life insurance company in Kenya offering financial assurance and assists in offering protection to your family in situations of your sudden or early death. Being the best life insurance company in Kenya, we offer various life insurance policies for which you can pay an insurance premium on a term basis for the time period mentioned in the policy. As a top life insurance company in Kenya, we in return are required to pay an assured sum to the family in case of the policy holder’s sudden death during the policy term.</p>
                                <p className="fs-5 text-primary mb-4">Important features of Life insurance</p>
                                <ul>
                                    <li>Ensure financial stability of the family</li>
                                    <li>Replace the loss during unexpected death</li>
                                    <li>Reduce stress in case of any unforeseen situations</li>
                                    <li>Supports your children education, spouse/ elders requirements</li>
                                    <li>Offers an assured sum to your family in case of your death during the policy term</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <h1 className="text-white text-center mb-4">Different types of life insurance policies we provide</h1>
                                <div className='d-flex justify-content-center my-2 mb-4'><hr className='w-25'/></div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Universal life insurance policy</h5>
                                            <p className="text-light">Sync Insurance Pay is one of the best life insurance companies in Kenya offering universal life insurance policy for the customers which offers a death benefit and a cash value.Universal life insurance policy comes with adjustable premiums where some of the cash can be adjusted to your yearly payment.</p>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Term life insurance policy</h5>
                                            <p className="text-light">Most of the life insurance companies suggest Term life insurance policy as it is the most affordable and usually preferred type of life insurance in Kenya by most of the customers. The main reason for this is it pays the persons you have chosen like your children, spouse, or other elders or beneficiaries. They will be given a fixed amount of money incase of your sudden death.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <div className="mb-2">
                                        <h5 className="fw-bold text-secondary mb-1">Whole life insurance</h5>
                                            <p className="text-white mb-0">Under the whole life insurance policy, you can keep the amount of your premium policy as long as you want the policy. You need to pay your premium life insurance policy amount to the company every month so that a small amount of that premium will go into the cash value and get a good growth over the whole life of the policy. The longer you pay the amount in this whole life insurance policy, the more profits you can earn.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h2 className="mb-4">Group Personal Accident for Students</h2>
                            <p className='mb-4'>This policy covers payments of benefits as defined by the policy resulting from accidental death/injury to the insured person(s)</p>
                            <div className='row justify-content-start mt-5'>
                                <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded h-100 p-5">
                                    <h2 className="mb-4">Group Personal Accident for Staff:</h2>
                                    <p>This policy covers payments of benefits as defined by the policy resulting from accidental death/injury to the insured person(s).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </>
  );
}

export default GroupLifeInsurance;