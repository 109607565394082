import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function GroupPersonalAccident() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Group Personal Accident</h3>
                    <p className='text-dark'>Business Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-group-personal-accident-insurance.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>account-group-outline</title><path d="M12,5A3.5,3.5 0 0,0 8.5,8.5A3.5,3.5 0 0,0 12,12A3.5,3.5 0 0,0 15.5,8.5A3.5,3.5 0 0,0 12,5M12,7A1.5,1.5 0 0,1 13.5,8.5A1.5,1.5 0 0,1 12,10A1.5,1.5 0 0,1 10.5,8.5A1.5,1.5 0 0,1 12,7M5.5,8A2.5,2.5 0 0,0 3,10.5C3,11.44 3.53,12.25 4.29,12.68C4.65,12.88 5.06,13 5.5,13C5.94,13 6.35,12.88 6.71,12.68C7.08,12.47 7.39,12.17 7.62,11.81C6.89,10.86 6.5,9.7 6.5,8.5C6.5,8.41 6.5,8.31 6.5,8.22C6.2,8.08 5.86,8 5.5,8M18.5,8C18.14,8 17.8,8.08 17.5,8.22C17.5,8.31 17.5,8.41 17.5,8.5C17.5,9.7 17.11,10.86 16.38,11.81C16.5,12 16.63,12.15 16.78,12.3C16.94,12.45 17.1,12.58 17.29,12.68C17.65,12.88 18.06,13 18.5,13C18.94,13 19.35,12.88 19.71,12.68C20.47,12.25 21,11.44 21,10.5A2.5,2.5 0 0,0 18.5,8M12,14C9.66,14 5,15.17 5,17.5V19H19V17.5C19,15.17 14.34,14 12,14M4.71,14.55C2.78,14.78 0,15.76 0,17.5V19H3V17.07C3,16.06 3.69,15.22 4.71,14.55M19.29,14.55C20.31,15.22 21,16.06 21,17.07V19H24V17.5C24,15.76 21.22,14.78 19.29,14.55M12,16C13.53,16 15.24,16.5 16.23,17H7.77C8.76,16.5 10.47,16 12,16Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">Group Personal Accident Insurance in Kenya</h1>
                                <p>Group Personal Accident Insurance (GPA) is a corporate or institutional policy issued to provide comprehensive financial protection against the financial losses due to the event of injuries, disability, or death caused by violent, accidental, external, and visible events. Being the best group accident insurance company in Kenya, we safeguard the employees from the risks of financial liability arising due to permanent disabilities or death.</p>
                                <p className="fs-5 text-primary mb-4">What does Sync Insurance Group Personal Accident Insurance Cover?</p>
                                <ul>
                                    <li>Accidental death of the insured individual</li>
                                    <li>Compensation for the insured in case of permanent partial/total disability</li>
                                    <li>Weekly group accident insurance for the temporary disability</li>
                                    <li>Group personal accident insurance cover provides reimbursement of ambulance charges</li>
                                    <li>Transportation charges to the hospital</li>
                                    <li>Policy covers for broken bones</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-4 justify-content-center">
                        <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded h-100 p-5">
                                <h5 className="fw-bold text-secondary mb-2">Benefits of Group Personal Accident Insurance</h5>
                                <ul>
                                    <li>If the policyholder is permanently disabled due to an accident, in that case, the group accident insurance pays for a predetermined percentage of the insured amount or a predetermined multiple of the salary</li>
                                    <li>If the policyholder loses life due to accidental death, the group personal accident insurance pays the total insured amount or a predetermined multiple of salary</li>
                                    <li>Our group personal accident insurance pays for the medical expenses of the insured person due to an accident which includes dental and optical expenses</li>
                                    <li>Permanent partial disabilities of the insured person due to an accident</li>
                                    <li>We offer weekly group accident insurance payments for a time period you are out of work because of the injuries due to an accident</li>
                                    <li>Group accident insurance ensures that the claim gets settled quickly</li>
                                </ul>
                            </div>
                        </div>
                   
                        <div className="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded h-100 p-5">
                                <h5 className="fw-bold text-secondary mb-2">Major Highlights of a Group Personal Accident Insurance</h5>
                                <ul className='mb-5'>
                                    <li>Group personal accident insurance offers comprehensive coverage for all expenses based on accidents</li>
                                    <li>100 % of the insured sum will be paid to the nominee in the case of an accidental death</li>
                                    <li>In case of temporary permanent disability, a particular percentage of insured amounts will be given to the policyholder</li>
                                    <li>In case of partial disablement, half of the insured amount will be paid</li>
                                    <li>Coverage for burns of the policyholder due to the accidents</li>
                                    <li>Group personal accident insurance covers for the insured’s broken bone due to an accident</li>
                                </ul>
                                <div className='row justify-content-start'>
                                    <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default GroupPersonalAccident;