import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function FireAndRelatedPerils() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
        <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Fire Insurance & Related Perils</h3>
                    <p className='text-dark'>Business Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-fire-insurance.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>fire</title><path d="M17.66 11.2C17.43 10.9 17.15 10.64 16.89 10.38C16.22 9.78 15.46 9.35 14.82 8.72C13.33 7.26 13 4.85 13.95 3C13 3.23 12.17 3.75 11.46 4.32C8.87 6.4 7.85 10.07 9.07 13.22C9.11 13.32 9.15 13.42 9.15 13.55C9.15 13.77 9 13.97 8.8 14.05C8.57 14.15 8.33 14.09 8.14 13.93C8.08 13.88 8.04 13.83 8 13.76C6.87 12.33 6.69 10.28 7.45 8.64C5.78 10 4.87 12.3 5 14.47C5.06 14.97 5.12 15.47 5.29 15.97C5.43 16.57 5.7 17.17 6 17.7C7.08 19.43 8.95 20.67 10.96 20.92C13.1 21.19 15.39 20.8 17.03 19.32C18.86 17.66 19.5 15 18.56 12.72L18.43 12.46C18.22 12 17.66 11.2 17.66 11.2M14.5 17.5C14.22 17.74 13.76 18 13.4 18.1C12.28 18.5 11.16 17.94 10.5 17.28C11.69 17 12.4 16.12 12.61 15.23C12.78 14.43 12.46 13.77 12.33 13C12.21 12.26 12.23 11.63 12.5 10.94C12.69 11.32 12.89 11.7 13.13 12C13.9 13 15.11 13.44 15.37 14.8C15.41 14.94 15.43 15.08 15.43 15.23C15.46 16.05 15.1 16.95 14.5 17.5H14.5Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">Best Fire Insurance Plans in Kenya to Offer Financial Support</h1>
                                <p>It is mandatory to protect your business assets, especially from the fire and related perils. Even if a small part is lost, it could lead to a great loss considering the competitive environment. The fire insurance can help you under such circumstances to provide financial support to the loss. The fire insurance policy in Kenya is a contract between the insured individual and the insurance company to indemnify the policyholder for the financial losses suffered due to the damage caused by the fire.</p>
                                <p>The fire insurance provides coverage for your property, industrial, and manufacturing risks. There are several fire insurance covers that meet your requirements. It includes coverage for the storage risks, offices, dwelling places, hotels, restaurants, shops, shopping malls, tanks & gas holders, etc. There is a wide range of add-on covers, attractive discounts, and customized coverage as well.</p>
                                <h5 className="text-primary mb-2">Key Features of Fire Insurance</h5>
                                <p>The fire insurance policy in Kenya cover covers the following perils:</p>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <ul>
                                            <li>Fire</li>
                                            <li>Lightning</li>
                                            <li>Riot, strike, malicious damages (RSMD)</li>
                                            <li>Landslide, subsidence & rock slide</li>
                                            <li>Bursting of water tanks, pipes and apparatus</li>
                                            <li>Leakage from sprinkler installations</li>
                                            <li>Missile testing operations</li>
                                            <li>Bush Fire</li>
                                        </ul>
                                    </div>
                                    <div className='col-md-6'>
                                        <ul>
                                            <li>Storm, hurricane, tornado, flood, tempest, cyclone, inundation, etc.</li>
                                            <li>Impact damage due to rail/ road/ vehicle/ animal (other than own)</li>
                                            <li>Landslide, subsidence & rock slide</li>
                                            <li>Bursting of water tanks, pipes and apparatus</li>
                                            <li>Leakage from sprinkler installations</li>
                                            <li>Explosion/implosion</li>
                                            <li>Flight damage</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <h1 className="text-white text-center mb-4">Types of Fire Insurance in Kenya</h1>
                                <div className='d-flex justify-content-center my-2 mb-4'><hr className='w-25'/></div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Special policy</h5>
                                            <p className="text-light">In this fire insurance policy in Kenya, a particular sum is insured upon a specified property for a specific period. If the actual amount doesn’t exceed the insured amount, then compensation is provided.</p>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Comprehensive policy</h5>
                                            <p className="text-light">The comprehensive fire insurance is provided not only against the risk of fire & related perils but also along with risks such as robbery, riot, civil damage, etc.</p>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Valuable Policy</h5>
                                            <p className="text-light">Being the best fire insurance in Kenya, we offer a valued policy where the amount of loss is not calculated at the time of commencement of risk and is determined at the time and place of loss.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Floating Policy</h5>
                                            <p className="text-white mb-0">We are the best fire insurance providing coverage to one or more kinds of goods at one time less than one sum assured and one premium covering fluctuating stocks in different localities.</p>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Average Policy</h5>
                                            <p className="text-white mb-0">This fire insurance policy contains an ‘average clause’ that the amount of indemnity is calculated based on the value of the property insured. If the policyholder has taken the policy which is comparatively less than the insured amount, then the insurer will pay only such a proportion of the actual loss.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gradient-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto" style={{maxWidth: 800}}>
                            <h1 className="display-6 mb-3">How fire insurance benefits business property?</h1>
                            <p className='mb-4'>There are many benefits of having fire insurance in Kenya for all the business owners. As a business owner, it is your business responsibility to take care of many things like business operations, finding different ways for your business growth, looking about financial data, etc.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded bg-white text-center h-100 p-5">
                                    <p className="mb-3">Because of all these aspects, you may not be able to focus on each and every possible risk that may be an obstacle for your company’s future. Sync Insurance is the top insurance company in Kenya offering best fire insurance plans to offer financial support and reduce the fire risks involved. As fire accidents are very common in the workplace, there may be chances for damage to your business property. There are many fire insurance companies in Kenya where you can check the best plans to protect your property. It is necessary for startups and small businesses to take preventive measures and reduce fire risks.</p>
                                    <div className='row justify-content-center'>
                                        <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default FireAndRelatedPerils;