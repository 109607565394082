import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function WibaInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">WIBA Insurance</h3>
                    <p className='text-dark'>Business Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-wiba.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>account-injury-outline</title><path d="M12 10C14.21 10 16 8.21 16 6S14.21 2 12 2 8 3.79 8 6 9.79 10 12 10M12 4C13.1 4 14 4.9 14 6S13.1 8 12 8 10 7.1 10 6 10.9 4 12 4M18.39 12.56C16.71 11.7 14.53 11 12 11S7.29 11.7 5.61 12.56C4.61 13.07 4 14.1 4 15.22V22H6V15.22C6 14.84 6.2 14.5 6.5 14.34C7.71 13.73 9.63 13 12 13C12.76 13 13.47 13.07 14.13 13.2L12.58 16.5H9.75C8.23 16.5 7 17.73 7 19.25S8.23 22 9.75 22H18C19.1 22 20 21.1 20 20V15.22C20 14.1 19.39 13.07 18.39 12.56M10.94 20H9.75C9.34 20 9 19.66 9 19.25S9.34 18.5 9.75 18.5H11.64L10.94 20M18 20H13.15L16.09 13.73C16.63 13.93 17.1 14.14 17.5 14.34C17.8 14.5 18 14.84 18 15.22V20Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">WORK INJURY BENEFITS ACT – WIBA INSURANCE</h1>
                                <p>Wiba Insurance is an employee benefit plan that helps employees to get financial compensation while on duty or any project undertaken by the policyholder against accidents or bodily injuries/disabilities or death. According to the WIBA Act 2007, the employee can claim for compensation against accidental bodily injury, disablement or death at work. Wiba Insurance can be availed by employees above the age of 18.</p>
                                <p className="fs-5 text-primary mb-2">What does Wiba Insurance Kenya pay for?</p>
                                <ul>
                                    <li>Accidental death</li>
                                    <li>Medical expenses</li>
                                    <li>Permanent total disablement</li>
                                    <li>Temporary total disablement</li>
                                    <li>Funeral expenses</li>
                                    <li>Occupational diseases & work-related injuries</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden my-5 px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 facts-text facts-text-wiba wow fadeIn" data-wow-delay="0.1s">
                            <div className="h-100 px-4 ps-lg-0">
                                <h1 className="text-white mb-4">Why do you need Wiba Insurance Kenya?</h1>
                                <p className='text-white'>Wiba Insurance is a necessary policy for all employees to protect against liabilities that arise from work-related injuries and occupational diseases. When the Wiba insurance is carried out internally, it could affect the company’s operations that your savings will drain off. Transferring those huge risks to an insurance company for the safety and well being of the employees is the best option as it won’t affect the company's financial planning.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 facts-counter facts-counter-wiba wow fadeIn" data-wow-delay="0.5s">
                            <div className="h-100 px-4 pe-lg-0">
                                <div className="row g-2">
                                    <div className="col-sm-12">
                                        <h1 className="fw-bold text-primary mb-4">The cost of Wiba Insurance Kenya</h1>
                                        <p className="text-dark mb-2">The cost of Wiba insurance in Kenya is not the same for all insurance companies. It depends on various factors such as the type of coverage plan according to your specific needs, company’s requirements, etc. The benefits that an insured gets from the coverage depend on the nature of the work-related bodily injuries, occupational diseases, employee’s earnings, etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h2 className="mb-4">What benefits does Wiba Insurance offer?</h2>
                            <p className='mb-2'>Compensates the insured in respect of death or injury to employees (including temporary and casual employees) arising out of and in the course of their employment.</p>
                            <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Wiba insurance offers good compensation for the employees in case of their death, injuries and occupational diseases (earnings up to 96 months)</p>
                            <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Weekly earnings due to a work-related accident (earnings up to 52 weeks)</p>
                            <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Permanent Total Disability ( earnings up to 96 months )</p>
                            <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Wiba insurance for medical expenses ( specified limit )</p>
                            <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>24/7 coverage</p>
                            <div className='row justify-content-start'>
                                <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded h-100 p-5">
                                    <h3 className="mb-4">Requirements needed for Wiba Insurance policy</h3>
                                    <ul>
                                        <li>Duly completed and signed proposal form</li>
                                        <li>Premium payment</li>
                                        <li>Coverage limits</li>
                                        <li>Company’s registration certificate</li>
                                        <li>Company’s PIN certificate</li>
                                        <li>Original medical bills</li>
                                        <li>Provincial documents</li>
                                        <li>Police abstract report in the case of accidents</li>
                                        <li>Schedule of the policyholders showing their names, category/positions and annual salaries</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </>
  );
}

export default WibaInsurance;