import React,{useEffect } from 'react';
import { scroller } from 'react-scroll';


import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Pages/Home';
import ContactUs from './components/Pages/ContactUs';
import MotorInsurance from './components/Services/MotorInsurance';
import HomeInsurance from './components/Services/HomeInsurance';
import PersonalAccidentInsurance from './components/Services/PersonalAccidentInsurance';
import TravelInsurance from './components/Services/TravelInsurance';
import MedicalInsurance from './components/Services/MedicalInsurance';
import LifeInsurance from './components/Services/LifeInsurance';
import EducationInsurance from './components/Services/EducationInsurance';
import GroupPersonalAccident from './components/Services/GroupPersonalAccident';
import FireAndRelatedPerils from './components/Services/FireAndRelatedPerils';
import ProfessionalIndemnityInsurance from './components/Services/ProfessionalIndemnityInsurance';
import WibaInsurance from './components/Services/WibaInsurance';
import SmeMedicalCover from './components/Services/SmeMedicalCover';
import GroupLifeInsurance from './components/Services/GroupLifeInsurance';
import BurglaryInsurance from './components/Services/BurglaryInsurance';

import { Route, Routes,useLocation } from "react-router-dom";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const scrollToAbout = () => {
    scroller.scrollTo('about', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };
  
  // const scrollToServices = () => {
  //   scroller.scrollTo('services', {
  //     duration: 800,
  //     delay: 0,
  //     smooth: 'easeInOutQuart'
  //   });
  // };

  const getStarted = () => {
    scroller.scrollTo('get-started', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  const scrollToTop = () => {
    scroller.scrollTo('navigation', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  return (
    <div className="App">
      <Header scrollToAbout={scrollToAbout} getStarted={getStarted} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/MotorInsurance" element={<MotorInsurance />} />
        <Route path="/HomeInsurance" element={<HomeInsurance />} />
        <Route path="/PersonalAccidentInsurance" element={<PersonalAccidentInsurance />} />
        <Route path="/TravelInsurance" element={<TravelInsurance />} />
        <Route path="/MedicalInsurance" element={<MedicalInsurance />} />
        <Route path="/LifeInsurance" element={<LifeInsurance />} />
        <Route path="/EducationInsurance" element={<EducationInsurance />} />
        <Route path='/GroupPersonalAccident' element={<GroupPersonalAccident/>}/>
        <Route path='/FireAndRelatedPerils' element={<FireAndRelatedPerils/>}/>
        <Route path='/ProfessionalIndemnityInsurance' element={<ProfessionalIndemnityInsurance/>}/>
        <Route path='/WibaInsurance' element={<WibaInsurance/>}/>
        <Route path='/SmeMedicalCover' element={<SmeMedicalCover/>}/>
        <Route path='/GroupLifeInsurance' element={<GroupLifeInsurance/>}/>
        <Route path='/BurglaryInsurance' element={<BurglaryInsurance/>}/>
      </Routes>
      <Footer scrollToTop={scrollToTop}/>
    </div>
  );
}

export default App;
