import React,{ useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';

function Services() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <Element name='services' className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto" style={{maxWidth: 500}}>
                    <h1 className="display-6 mb-5">
                        We Provide professional Insurance Services
                    </h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        <NavLink to="/OurServices#personal-covers" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded h-100 p-5">
                            <div className="d-flex align-items-center ms-n5 mb-4">
                                <div className="service-icon flex-shrink-0 bg-primary rounded-end me-4">
                                <img className="img-fluid" src="img/icon/icon-10-light.png" alt />
                                </div>
                                <h4 className="mb-0">Personal Covers</h4>
                            </div>
                            <p className="mb-4">
                                Designed to safeguard you and your loved ones against life's uncertainties. From auto and home insurance to life and health coverage, we recognize the importance of securing your assets and ensuring financial protection for your family.
                            </p>
                            </div>
                        </NavLink>
                   
                        <NavLink to="/OurServices#business-covers" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded h-100 p-5">
                            <div className="d-flex align-items-center ms-n5 mb-4">
                                <div className="service-icon flex-shrink-0 bg-primary rounded-end me-4">
                                <img className="img-fluid" src="img/icon/icon-07-light.png" alt />
                                </div>
                                <h4 className="mb-0">Business and Corporate Covers</h4>
                            </div>
                            <p className="mb-4">Running a business involves inherent risks, and having the right insurance coverage is essential to protect your enterprise. At Sync Insurance Agency, we specialize in providing comprehensive business insurance solutions tailored to your industry and operational needs. </p>
                            </div>
                        </NavLink>
                            
                        <NavLink to="/OurServices#all-covers" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded h-100 p-5">
                            <div className="d-flex align-items-center ms-n5 mb-4">
                                <div className="service-icon flex-shrink-0 bg-primary rounded-end me-4">
                                <img className="img-fluid" src="img/icon/icon-06-light.png" alt />
                                </div>
                                <h4 className="mb-0">All Other Covers</h4>
                            </div>
                            <p className="mb-4">Sync Insurance Agency offers a diverse array of specialty coverages to address your unique requirements. Whether you need cyber liability insurance to protect your digital assets, directors and officers...</p>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </Element>
        </div>
    </>
  );
}

export default Services;