import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function EducationInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Education Insurance</h3>
                    <p className='text-dark'>Personal Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-8.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24">
                                            <title>school-outline</title>
                                            <path d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3M18.82 9L12 12.72L5.18 9L12 5.28L18.82 9M17 16L12 18.72L7 16V12.27L12 15L17 12.27V16Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">Best Education Insurance in Kenya to Meet Your Educational Needs</h1>
                                <p>Sync Insurance Agency is the best education insurance company in Kenya offering the best education insurance policies that provide financial security and support your child's education. Correct investments in education insurance policies save the future of the child. Being a top education insurance company in Kenya, Sync Insurance Agency offers various education insurance policies that support the educational needs of the students.</p>
                                <p>Sync Insurance Agency is the leading education insurance company in Kenya offering affordable education insurance policies specially designed to offer an amount of education cost for the education of your kids till they reach the age of 18 years or above. These funds help to offer financial security to your child’s future educational requirements like paying college fee, hostel fee and illness expenses. Under this education insurance plan, a student life will be assured, while parents will be the insurance policy owner.</p>
                                <p className="fs-5 text-primary mb-4">Key features of Education insurance</p>
                                <ul>
                                    <li>Avail full fund value of your education insurance plan</li>
                                    <li>Helps to meet your child’s education goals</li>
                                    <li>Offers an assured sum value upon maturity</li>
                                    <li>Long term benefits and even useful for student hostel fee, illness, etc.</li>
                                    <li>Guaranteed sum assurance in case of sudden death.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <h1 className="text-white text-center mb-0">Different types of education insurance policies we provide</h1>
                                <div className='d-flex justify-content-center my-2 mb-0'><hr className='w-25 my-5'/></div>
                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Endowment policy</h5>
                                        <p className="text-light mb-4">Endowment policies are much similar to a bank account which offers all the education insurance benefits. Education insurance policy seems much like an insurance policy in many ways but is created specially to ensure the future educational requirements of your children. In investment-linked policies you can invest the funds and still maintain coverage. As a reputed education insurance company in Kenya, Sync Insurance Agency offers suitable education insurance policies that help in the education of your children in the long-run.</p>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Investment linked policy</h5>
                                        <p className="text-light mb-5">Under investment linked policy, well-performing funds can earn special rewards and benefits to be paid when the plan reaches adulthood. It is very expensive to maintain investment linked policy whereas endowment policy generally costs less. Most of the customers prefer endowment policy over investment linked policy as it costs less. Sync Insurance Agency is the best insurance company in Kenya that provides both endowment and investment policies according to the customer needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gradient-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto" style={{maxWidth: 800}}>
                            <h3 className="display-6 mb-3">Education Insurance in Kenya to Secure Your Children's Future</h3>
                            <p>Education Insurance Plans in Kenya are mainly useful to take care of your savings for securing your children’s future. As a parent, one of your most essential goals is to ensure that your children are going to have a bright future and lead a comfortable life. </p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded bg-white text-center h-100 p-5">
                                    <p className="mb-3">These education insurance plans in Kenya can support you in achieving this by making good savings for the higher education of your children at any reputed universities. Our education policy allows you to plan for future expenses and secure your child’s education - no matter what happens.</p>
                                    <h4 className="mb-3 text-primery">Saving for education made easy</h4>
                                    <p className="mb-4">Let us help you to start saving for your child’s future today.

Education is the greatest gift we can give our children. That’s why saving for their university or college education from as early as you can is becoming more important than ever.

Find out today by requesting for a quote or speaking to one of our professional financial advisers.</p>
                                <div className='row justify-content-center'>
                                    <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default EducationInsurance;