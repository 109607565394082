import React,{} from 'react';
import { NavLink } from 'react-router-dom';

function Features() {
  return (
    <>
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="display-6 mb-5">Why Choose Sync Insurance Agency?</h1>
                        <p className="mb-4">We offer a meticulously curated selection of insurance products from all major Kenyan insurance providers, ensuring you have access to the most comprehensive coverage options available.</p>
                        <div className="row g-3">
                            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                <div className="bg-light rounded h-100 p-3">
                                    <div className="bg-white d-flex flex-column justify-content-center text-center rounded h-100 py-4 px-3">
                                        <img className="align-self-center mb-3" src="img/icon/icon-06-primary.png" alt />
                                        <p className="text-dark mb-0 fw-bold">Unparalleled Choice & Expert Guidance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                                <div className="bg-light rounded h-100 p-3">
                                    <div className="bg-white d-flex flex-column justify-content-center text-center rounded py-4 px-3">
                                        <img className="align-self-center mb-3" src="img/icon/icon-03-primary.png" alt />
                                        <p className="text-dark mb-0 fw-bold">Competitive Rates & Streamlined Processes</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                <div className="bg-light rounded h-100 p-3">
                                    <div className="bg-white d-flex flex-column justify-content-center text-center rounded py-4 px-3">
                                        <img className="align-self-center mb-3" src="img/icon/icon-04-primary.png" alt />
                                        <p className="text-dark mb-0 fw-bold">Peace of Mind Through In-Depth Understanding</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                <div className="bg-light rounded h-100 p-3">
                                    <div className="bg-white d-flex flex-column justify-content-center text-center rounded h-100 py-4 px-3">
                                        <img className="align-self-center mb-3" src="img/icon/icon-07-primary.png" alt />
                                        <p className="text-dark mb-0 fw-bold">Your Long-Term Insurance Partner</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="position-relative rounded overflow-hidden h-100" style={{minHeight: 400}}>
                        <img className="position-absolute w-100 h-100" src="img/feature.jpg" alt style={{objectFit: 'cover'}} />
                        </div>
                    </div>
                    </div>
                </div>
                </div>

        </div>
    </>
  );
}

export default Features;