import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function HomeInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Home Insurance</h3>
                    <p className='text-dark'>Personal Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-home-insurance.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>home-city-outline</title><path d="M10,2V4.26L12,5.59V4H22V19H17V21H24V2H10M7.5,5L0,10V21H15V10L7.5,5M14,6V6.93L15.61,8H16V6H14M18,6V8H20V6H18M7.5,7.5L13,11V19H10V13H5V19H2V11L7.5,7.5M18,10V12H20V10H18M18,14V16H20V14H18Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">HOME INSURANCE SOLUTION THAT SUIT YOUR NEEDS</h1>
                                <p>We are the best home insurance in Kenya offering affordable insurance plans to protect your property and its home contents against the damages caused by fire, burglary, flooding, and other unforeseen events. Our home insurance Kenyan company fully covers all expenses of the damage. It also includes your personal belongings such as laptops, Mobile Phones, Camera, etc.</p>
                                <p className="fs-5 text-primary mb-4">Why Sync home insurance?</p>
                                <p>Being the best home insurance in Kenya, we offer comprehensive protection from difficult and unavoidable circumstances, and focus on ensuring safety to your home, its contents and your belongings.</p>
                                <p className="fs-5 text-primary mb-4">Importance Features of Home Insurance</p>
                                <ul>
                                    <li>Ensure protection to the home and its contents</li>
                                    <li>Protect you from the financial loss due to natural calamities</li>
                                    <li>In case of theft and burglary</li>
                                    <li>Cost-effective home insurance solutions</li>
                                    <li>Protects you from lawsuits</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <h1 className="text-white text-center mb-4">Major Types of Home Insurance</h1>
                                <div className='d-flex justify-content-center my-2 mb-4'><hr className='w-25'/></div>
                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Dwelling coverage</h5>
                                        <p className="text-light mb-1">Sync Insurance Agency offers home insurance policies for protecting the whole structure of the home including walls, doors & windows, ceilings, and other built-in appliances. The home insurance helps you to rebuild the home in case of a total loss (under policy limits), fire, natural calamities, breakdowns, theft, etc.</p>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Contents coverage</h5>
                                        <p className="text-light mb-1">As the best Kenyan home insurance agency, we provide financial support to the home belongings such as furniture, clothing, etc. We help you to repair or replace the contents. Fund will be provided for the value of each item as mentioned in the policy. The personal property coverage is of mainly two types. One is replacement cost coverage and the other is actual cash value coverage.</p>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Other optional coverage</h5>
                                        <p className="text-light mb-1">This includes a plethora of other home insurance coverage. You can avail earthquake insurance in case of a destructible earthquake. Flood insurance covers the home and its belongings if the area is prone to flood and this home insurance depends on the geographical location. The insurance also comprises the ordinance or law insurance, roof replacement, and in case of a sewer or drain backup the insurance protects the home and property.</p>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Personal liability coverage</h5>
                                        <p className="text-light mb-1">The personal liability in home insurance includes the physical body injuries, property damage cover in case the property is damaged due to the recovered accident.</p>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gradient-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto" style={{maxWidth: 800}}>
                            <h1 className="display-6 mb-5">Home Insurance in Kenya</h1>
                            <p>Home insurance offers coverage against various man-made disasters, compensation for damage of assets, protection against liability, etc. It is very necessary to have home insurance in Kenya to avail all these benefits and compensations.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded bg-white text-center h-100 p-5">
                                    <h4 className="mb-3 text-primery">Why is Home Insurance essential?</h4>
                                    <p className="mb-0">Home insurance offers coverage against natural calamities such as cyclones, floods, and earthquakes, which create major damage to property and life. A home insurance policy in Kenya offers coverage for the loss occurring to your home due to various causes. Apart from natural disasters, a home insurance policy also offers coverage for damages occurring due to man-made disasters like theft, fire, etc. It also offers coverage for temporary living expenses incase if your home is not in a good condition due to severe damage you can avail a home insurance policy in Kenya which can offer coverage or compensation for your living expenses on a temporary basis until your home is rebuilt or repaired. Sync Insurance Agency is the best insurance agency in Kenya which offers home insurance policies to get coverage against loss of assets like ornaments, clothes, furniture, electronic appliances, etc. JIA is the top insurance agency in Kenya which offers protection against liabilities by offering good insurance coverages. A home insurance policy in Kenya also offers protection in case of any liability which arises out of accidental damage occurring due to a third party member within the boundaries of your property.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default HomeInsurance;