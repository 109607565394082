import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function Action() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);
    const phoneNumber = '+254726901994';

    const handleCallClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

  return (
    <>
        <div>
            <Element name='get-started' className="container-fluid appointment my-5 py-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.3s">
                        <h1 className="display-6 text-white mb-5">
                        Get Started Today
                        </h1>
                        <p className="text-white mb-3">
                        Take the first step towards protecting your future – contact Sync Insurance Agency today. Whether you're seeking personal, business, or specialty insurance coverage, we're here to help. 
                        </p>
                        <p className="text-white mb-5">Let us be your trusted insurance partner, providing you with the peace of mind and financial security you deserve. Schedule a consultation with one of our knowledgeable agents, and let us tailor a solution that meets your needs perfectly. Your protection is our priority.</p>
                        <NavLink onClick={handleCallClick} className="d-flex align-items-center bg-primary rounded p-3">
                            <img className="flex-shrink-0 rounded animated pulse infinite me-3" src="img/call.png" alt />
                            <h5 className="text-white mb-0">Call Us: +254 726 901 994</h5>
                        </NavLink>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <div className="bg-white rounded p-5">
                        <form>
                            <div className="row g-3">
                            <div className="col-sm-6">
                                <div className="form-floating">
                                <input type="text" className="form-control" id="gname" placeholder="Gurdian Name" />
                                <label htmlFor="gname">Your Name</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                <input type="email" className="form-control" id="gmail" placeholder="Gurdian Email" />
                                <label htmlFor="gmail">Your Email</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                <input type="text" className="form-control" id="cname" placeholder="Child Name" />
                                <label htmlFor="cname">Your Mobile</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                <input type="text" className="form-control" id="cage" placeholder="Child Age" />
                                <label htmlFor="cage">Service Type</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: 80}} defaultValue={""} />
                                <label htmlFor="message">Message</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary py-3 px-5" type="submit">
                                Get Appointment
                                </button>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
            </Element>
        </div>
    </>
  );
}

export default Action;