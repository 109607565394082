import React,{} from 'react';
import { NavLink } from 'react-router-dom';

const Footer = ({ scrollToTop }) => {
  return (
    <>
        <div>
            <div className="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h1 className="text-white mb-4">
                        <img className="img-fluid me-3" height="15px" src="SYNC-INSURANCE-AGENCY-WHITE.png" alt />
                        </h1>
                        <div className="d-flex">
                        <a className="btn btn-square me-1" href><i className="fab fa-twitter" /></a>
                        <a className="btn btn-square me-1" href><i className="fab fa-facebook-f" /></a>
                        <a className="btn btn-square me-0" href><i className="fab fa-linkedin-in" /></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Visit Us</h5>
                        <p className='d-flex align-items-start gap-3'><i className="fa fa-map-marker-alt mt-2" />KU-Plaza- 6th Floor, <br/>Haile Selassie Avenue</p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Call Us</h5>
                        <p className='d-flex align-items-start gap-3'><i className="fa fa-phone mt-2" /><span>+254 202 033 422<br/>+254 726 901 994</span></p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Write Us</h5>
                        <p className='d-flex align-items-start gap-3'><i className="far fa-envelope-open mt-2" />
                        <span>P.O BOX 5719-00100 Nairobi<br/>info@syncinsuranceagency.company</span></p>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Newsletter</h5>
                        <small>Sign up for Sync Insurance Agency's newsletter to stay informed about the latest trends, updates, and tips in the world of insurance.</small>
                        <div className="position-relative mx-auto mt-2" style={{maxWidth: 400}}>
                            <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <button type="button" className="btn btn-light py-2 position-absolute top-0 end-0 mt-2 me-2">
                                Join
                            </button>
                        </div>
                    </div> */}
                    </div>
                </div>
                <div className="container-fluid copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            © <NavLink to="/">SYNC Insurance Agency</NavLink>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                            {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                            Designed By <a href="https://crescentnetworks.co.ke/">Crescent Networks</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            {/* Back to Top */}
            <NavLink to="/" onClick={scrollToTop} className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></NavLink>

        </div>
    </>
  );
}

export default Footer;