import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function SmeMedicalCover() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Corporate / SME Medical Cover</h3>
                    <p className='text-dark'>Business Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-sme-medical-cover.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>medical-bag</title><path d="M10,3L8,5V7H5C3.85,7 3.12,8 3,9L2,19C1.88,20 2.54,21 4,21H20C21.46,21 22.12,20 22,19L21,9C20.88,8 20.06,7 19,7H16V5L14,3H10M10,5H14V7H10V5M11,10H13V13H16V15H13V18H11V15H8V13H11V10Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">Corporate Insurance / SME Medical Cover</h1>
                                <p>We are the best corporate insurance company offering customized and affordable corporate Insurance plans for corporate and SME medical covers to the company’s employees for their well-being and to protect their health. Being a responsible corporate insurance company, we make sure that our modular international corporate insurance plans are cost-effective, flexible, and adaptive for our clients.</p>
                                <p className="fs-5 text-primary mb-4">Why Sync Insurance Corporate Insurance Company?</p>
                                <p>We provide the best corporate insurance plans and agile services that give extra benefits to your small and medium enterprises.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">We protect your savings</h5>
                                            <p className="text-light">Being the best corporate insurance Kenya, we help you to control the costs as we won’t raise the premium offer after your employees make the claim.</p>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Secure online portal</h5>
                                            <p className="text-light">Our corporate insurance company offers a secure online portal to help you in managing your business insurance covers and offer other services such as view details of insurance cover, setup and manage policies, add family members, create cover reports, and more.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Custom-made coverage</h5>
                                            <p className="text-white mb-0">Our corporate insurance company Kenya offers tailored packages that suit your business needs.</p>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Best Insurance Premium match</h5>
                                            <p className="text-white mb-0">Our corporate insurance company tries our best to match with other cheaper quotes you get elsewhere.</p>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="fw-bold text-secondary mb-1">Trusted and dedicated service</h5>
                                            <p className="text-white mb-0">As we are the top corporate insurance company Kenya, we believe in offering the perfect corporate solutions for your business with utmost dedication and trust.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h2 className="mb-4">What Sync Insurance Corporate Insurance Company covers?</h2>
                            <p className='mb-4'>Our business corporate solutions and comprehensive packages are flexible and tailored to your unique business needs with a plethora of optional features. You can choose any corporate insurance plan based on your requirements and cost. As our corporate insurance company works seamlessly to support businesses and enterprises, we provide strong support to protect the employee’s financial base and security.</p>
                            <p className='mb-5'>We have various Insurance plans for corporate and SME medical covers to enable your company employees to access superior in-patient and out-patient healthcare services through various accredited healthcare service providers anytime they need to.</p>
                            <div className='row justify-content-start mt-5'>
                                <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded h-100 p-5">
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Complete employee access to superior in-patient and out-patient healthcare services</p>
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Best coverage for inpatient and day-patient treatment services</p>
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Accommodation facilities in private hospitals</p>
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Our corporate insurance Kenya offers outpatient treatment services such as special fees, charges for diagnostic tests and physiotherapy charges</p>
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Cash benefit for using the NHS</p>
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Policyholder can choose the hospital and consultation</p>
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Best comprehensive cancer cover</p>
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Best corporate insurance to protect your business and employees</p>
                                    <p className='text-primary mb-3 fw-bold d-flex'><span className="fa fa-check me-2"></span>Employees can add their families</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </>
  );
}

export default SmeMedicalCover;