import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

function About() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <Element name="about" className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                        <img className="position-absolute w-100 h-100" src="img/about.jpg" alt style={{objectFit: 'cover'}} />
                        <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                            <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                            <h1 className="text-white mb-0">10+</h1>
                            <h2 className="text-white">Years</h2>
                            <h5 className="text-white mb-0">Experience</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="h-100">
                        <h1 className="display-6 mb-5">
                            We're our Trusted Partner for All Your Insurance Needs
                        </h1>
                        <p className="fs-5 text-primary mb-4">
                            We offer a comprehensive range of personal, business, and corporate coverages to safeguard what matters most to you. We work with all the major insurance providers in Kenya, allowing us to find the most competitive rates and tailored policies for your specific needs.
                        </p>
                        <div className="row g-4 mb-4">
                            <div className="col-sm-6">
                            <div className="d-flex align-items-center">
                                <img className="flex-shrink-0 me-3" src="img/icon/icon-04-primary.png" alt />
                                <h5 className="mb-0">Flexible Insurance Plans</h5>
                            </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="d-flex align-items-center">
                                <img className="flex-shrink-0 me-3" src="img/icon/icon-03-primary.png" alt />
                                <h5 className="mb-0">Money Back Guarantee</h5>
                            </div>
                            </div>
                        </div>
                        <p className="mb-4">
                        At Sync Insurance Agency, we understand that finding the right insurance coverage can be a daunting task. That's why we're here to simplify the process and provide you with tailored solutions to meet your needs. 
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
            </Element>
        </div>
    </>
  );
}

export default About;