import React,{ useState,useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

function ContactUs() {
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
            scroller.scrollTo(hash, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        fetch('https://syncinsuranceagency.com/sendContactEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: event.target.name.value,
                email: event.target.email.value,
                subject: event.target.subject.value,
                message: event.target.message.value
            })
        })
        .then(response => {
            if (response.ok) {
                alert('Message sent successfully!');
                // Clear form fields if submission was successful
                event.target.reset();
            } else {
                throw new Error('Failed to send message');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Failed to send message. Please try again later.');
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    };

    return (
        <div>
           <div>
                {/* Page Header Start */}
                <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <h1 className="display-4 animated slideInDown mb-4">Contact Us</h1>
                        <p className='text-dark'>Get in touch with us.</p>
                    </div>
                </div>
                {/* Page Header End */}
                {/* Contact Start */}
                <div className="container-xxl py-5">
                    <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <h1 className="display-6 mb-5">
                            If You Have Any Query, Please Contact Us
                        </h1>
                        <p className="mb-4">
                        We're here to answer your questions, help you find the right coverage, and guide you through the insurance process.  Feel free to reach out to us using the method that best suits you
                        </p>
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-floating">
                                    <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                    <label htmlFor="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                    <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                    <label htmlFor="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                    <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                    <label htmlFor="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                    <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: 100}} defaultValue={""} />
                                    <label htmlFor="message">Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary py-3 px-5" type="submit">
                                    {isSubmitting ? 'Sending...' : 'Send Message'}
                                    </button>
                                </div>
                            </div>
                        </form>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: 450}}>
                            <div className="position-relative rounded overflow-hidden h-100">
                                <iframe className="position-relative w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.221809156812!2d36.8225998!3d-1.2910608!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10df2cc236c7%3A0x579fa67bb5af8f5a!2sKU%20Plaza!5e0!3m2!1sen!2ske!4v1711223347922!5m2!1sen!2ske" frameBorder={0} style={{minHeight: 450, border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Contact End */}
            </div>

        </div>
    );
}

export default ContactUs;