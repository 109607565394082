import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function LifeInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Life Insurance</h3>
                    <p className='text-dark'>Personal Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-3.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>account-supervisor-outline</title><path d="M16.5 15.5C18.22 15.5 20.25 16.3 20.5 16.78V17.5H12.5V16.78C12.75 16.3 14.78 15.5 16.5 15.5M16.5 14C14.67 14 11 14.92 11 16.75V19H22V16.75C22 14.92 18.33 14 16.5 14M9 13C6.67 13 2 14.17 2 16.5V19H9V17.5H3.5V16.5C3.5 15.87 6.29 14.34 9.82 14.5A5.12 5.12 0 0 1 11.37 13.25A12.28 12.28 0 0 0 9 13M9 6.5A1.5 1.5 0 1 1 7.5 8A1.5 1.5 0 0 1 9 6.5M9 5A3 3 0 1 0 12 8A3 3 0 0 0 9 5M16.5 8.5A1 1 0 1 1 15.5 9.5A1 1 0 0 1 16.5 8.5M16.5 7A2.5 2.5 0 1 0 19 9.5A2.5 2.5 0 0 0 16.5 7Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">Best Life Insurance Company in Kenya to Resolve Unexpected Problems</h1>
                                <p>Sync Insurance Agencyis the best Life insurance company in Kenya offering financial assurance and assists in offering protection to your family in situations of your sudden or early death. Being the best life insurance company in Kenya, we offer various life insurance policies for which you can pay an insurance premium on a term basis for the time period mentioned in the policy. As a top life insurance company in Kenya, we in return are required to pay an assured sum to the family in case of the policy holder’s sudden death during the policy term.</p>
                                <p className="fs-5 text-primary mb-4">Important features of Life insurance</p>
                                <ul>
                                    <li>Ensure financial stability of the family</li>
                                    <li>Replace the loss during unexpected death</li>
                                    <li>Reduce stress in case of any unforeseen situations</li>
                                    <li>Supports your children education, spouse/ elders requirements</li>
                                    <li>Offers an assured sum to your family in case of your death during the policy term</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <h1 className="text-white text-center mb-2">Different types of life insurance policies we provide</h1>
                                <div className='d-flex justify-content-center my-2 mb-4'><hr className='w-25 my-5'/></div>
                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Whole life insurance</h5>
                                        <p className="text-light mb-4">Under the whole life insurance policy, you can keep the amount of your premium policy as long as you want the policy. You need to pay your premium life insurance policy amount to the company every month so that a small amount of that premium will go into the cash value and get a good growth over the whole life of the policy. The longer you pay the amount in this whole life insurance policy, the more profits you can earn.</p>
                                    
                                        <h5 className="fw-bold text-secondary mb-2">Universal life insurance policy</h5>
                                        <p className="text-light mb-4">Sync Insurance Agency is one of the best life insurance companies in Kenya offering universal life insurance policy for the customers which offers a death benefit and a cash value.Universal life insurance policy comes with adjustable premiums where some of the cash can be adjusted to your yearly payment.</p>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Term life insurance policy</h5>
                                        <p className="text-light mb-5">Most of the life insurance companies suggest Term life insurance policy as it is the most affordable and usually preferred type of life insurance in Kenya by most of the customers. The main reason for this is it pays the persons you have chosen like your children, spouse, or other elders or beneficiaries. They will be given a fixed amount of money incase of your sudden death.</p>
                                        <NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gradient-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto" style={{maxWidth: 800}}>
                            <h3 className="display-6 mb-3">Life Insurance in Kenya to Safeguard You and Your Family</h3>
                            <p>Accidents and other mishappenings are great indicators of how delicate human life is, showing us the requirement to insure our lives. Life insurance in Kenya is an essential tool for offering safety and security to the family of an individual. </p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded bg-white text-center h-100 p-5">
                                    <p className="mb-3">Life insurance in Kenya will offer coverage to protect the insured's dependents. If the individuals do not insure their lives, it will be difficult for their dependents to face many challenges like loans, rent, child services, and EMI's.</p>
                                    <p className="mb-3">Guarantee your family’s future with the right insurance and savings solutions, no matter what life might bring. We offer various life insurance plans from reputable insurers to ensure that you are properly covered for all life’s important moments.</p>
                                    <p className="mb-4">Whether you need life insurance to ensure your family and other dependants are financially secure in the event of your death, or funeral cover to lessen the burden on your loved ones, we can help.</p>
                                    <NavLink to="/ContactUs" className="btn btn-primary text-white py-3 px-5">Get A Quote</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default LifeInsurance;