import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function TravelInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Travel Insurance</h3>
                    <p className='text-dark'>Personal Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-travel-insurance.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>train-car</title><path d="M12,4H5A3,3 0 0,0 2,7V15A3,3 0 0,0 5,18L4,19V20H5L7,17.97L9,18V13H4V6H13V8H15V7A3,3 0 0,0 12,4M5,14A1,1 0 0,1 6,15A1,1 0 0,1 5,16A1,1 0 0,1 4,15A1,1 0 0,1 5,14M20.57,9.66C20.43,9.26 20.05,9 19.6,9H12.41C11.95,9 11.58,9.26 11.43,9.66L10,13.77V19.28C10,19.66 10.32,20 10.7,20H11.32C11.7,20 12,19.62 12,19.24V18H20V19.24C20,19.62 20.31,20 20.69,20H21.3C21.68,20 22,19.66 22,19.28V17.91L22,13.77L20.57,9.66M12.41,10H19.6L20.63,13H11.38L12.41,10M12,16A1,1 0 0,1 11,15A1,1 0 0,1 12,14A1,1 0 0,1 13,15A1,1 0 0,1 12,16M20,16A1,1 0 0,1 19,15A1,1 0 0,1 20,14A1,1 0 0,1 21,15A1,1 0 0,1 20,16Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">THE BEST TRAVEL INSURANCE IN KENYA TO FULFILL YOUR TRAVEL DREAMS</h1>
                                <p>We are the best travel insurance Kenyan company offering affordable travel insurance coverage and special travel insurance plans against all possible risks such as loss of passport, personal accident, any medical emergencies, etc. that might occur during the journey. Having travel insurance protects you from all medical and financial hurdles.</p>
                                <p className="fs-5 text-primary mb-4">Major Features</p>
                                <ul>
                                    <li>24/7 travel insurance services</li>
                                    <li>Deal the financial loss due to trip cancellation, emergency medical situations, injuries, and accidents/ death.</li>
                                    <li>Provides medical assistance in remote areas</li>
                                    <li>Offers stress free travel</li>
                                    <li>Covers sudden injury and medical illness</li>
                                    <li>It can be extended if there is any change in the travel plans</li>
                                    <li>Covers for socio-political circumstances</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <h1 className="text-white text-center mb-2">Important Travel<br/> Insurance Coverage</h1>
                                <p className='text-white text-center mb-4'>There are many travel insurance coverage to help you in tackling financial losses that might occur during travel.</p>
                                <div className='d-flex justify-content-center my-2 mb-4'><hr className='w-25'/></div>
                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Flights Delays and Cancellations</h5>
                                        <p className="text-light mb-4">Our travel insurance in Kenya offers flight cancellation coverage and flight delay coverage. Due to adverse weather conditions, health issues, family medical emergencies, or other social conditions, the flight might get canceled. Under those situations, the travel insurance plan helps you to tackle the financial loss and the flight and hotel booking charges will be reimbursed.</p>
                                    
                                        <h5 className="fw-bold text-secondary mb-2">Trip Cancellation Charges</h5>
                                        <p className="text-light mb-4">Due to some unforeseen circumstances, you might be forced to cancel the trip. To bear with the cost of the trip, you need travel insurance to cover your financial loss due to trip cancellation and curtailment. Being the best travel insurance in Kenya, we help you to deal with unexpected financial losses.</p>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Loss of Passport and Baggage</h5>
                                        <p className="text-light mb-4">Travel insurance can be availed when a policyholder loses his baggage during the trip due to a theft or some other mishap. The plan includes the personal items or other goods of the insured individual. It is available for both domestic and international plans. Our travel insurance helps you to travel stress-free, fully concentrate, and enjoy the trip.</p>
                                    
                                        <h5 className="fw-bold text-secondary mb-2">Medical Treatment Expenses</h5>
                                        <p className="text-light mb-4">Our travel insurance in Kenya plan offers travel health insurances to help them during unexpected medical contingencies or other emergency situations. The major features include cashless hospitalization facilities, seamless medical services, and this travel insurance is available for frequent and occasional travellers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gradient-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto" style={{maxWidth: 800}}>
                            <h3 className="display-6 mb-3">Advantages of Travel Insurance in Kenya</h3>
                            <p className='mb-4'>The major benefits of having Travel Insurance in Kenya is that it covers loss of passport, loss of checked-in baggage, etc. By offering coverage against these losses, it offers compensation for the traveller for expenses that may be caused due to these losses.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded bg-white text-center h-100 p-5">
                                    <p className="mb-3">One of the major reasons for opting Travel Insurance in Kenya is to compensate against cost for medical treatment or accidents occurred during travel. In case of any accident, the expenses for the medical treatment will be reimbursed by the insurance company. Even the travel insurance companies in Kenya which offer the best travel insurance coverage will have contacts with many hospitals in various countries through which they offer cashless treatment. Sync Insurance Agency is a top Insurance company in Kenya offering all the travel insurance coverages ensuring the insured person will not be having any problems. </p>
                                    <p className="mb-0">We also offer personal liability coverage where the damage is caused to the third person by the insured person. By chance, if any damage is happened to the third person during travelling, then the travel insurance can benefit you by offering the compensation. Travel insurance agencies in Kenya also offer third party liability which may offer coverage for the property or person.</p>
                                <div className='row justify-content-center mt-5'>
                                    <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default TravelInsurance;