import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import HomeHero from './Sections/Hero';
import About from './Sections/About';
import Facts from './Sections/Facts';
import Features from './Sections/Features';
import Services from './Sections/Services';
import Action from './Sections/Action';
import Testimonials from './Sections/Testimonials';


function Home() {
  const options = {
    loop: true,
    margin: 10,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 2,
            nav: false
        },
        1000: {
            items: 6,
            nav: true,
            margin: 20
        }
    },
    autoplay: true, // Add this line for autoplay
    autoplayTimeout: 4000,
    slideBy: 1,
    autoplaySpeed: 800
  };
   
  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash) {
      scroller.scrollTo(hash, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  }, []);

  const scrollToAbout = () => {
    scroller.scrollTo('about', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  return (
    <div>
        <HomeHero scrollToAbout={scrollToAbout}/>
        <About/>
        <Facts/>
        <Services/>
        <Features/>
        <Action/>
        <Testimonials/>

        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto" style={{maxWidth: 500}}>
                    <h1 className="display-6 mb-5">What They Say About Our Insurance</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                            <OwlCarousel className="owl-theme" {...options}>
                                <div className="item partner-item text-center">
                                  <img className="img-fluid me-3" src="img/partners/jubilee-logo.png" height="30px" alt />
                                </div>
                                <div className="item partner-item text-center">
                                  <img className="img-fluid me-3" src="img/partners/britam.png" height="30px" alt />
                                </div>
                                <div className="item partner-item text-center">
                                  <img className="img-fluid me-3" src="img/partners/ga-insurance-group-logo.svg" height="30px" alt />
                                </div>
                                <div className="item partner-item text-center">
                                  <img className="img-fluid me-3" src="img/partners/Apa_Insurance_Logo.jpg" height="30px" alt />
                                </div>
                                <div className="item partner-item text-center">
                                  <img className="img-fluid me-3" src="img/partners/kenindia.jpeg" height="30px" alt />
                                </div>
                                <div className="item partner-item text-center">
                                  <img className="img-fluid me-3" src="img/partners/uap.jpg" height="30px" alt />
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Home;