import React, { useState, useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

import { Link, NavLink  } from "react-router-dom";
///import Menu from './Sections/Menu';

const Header = ({ scrollToAbout,getStarted }) => {
    const [isLoading, setIsLoading] = useState(true);

    ///const location = useLocation();

    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }

        // Simulating loading delay
        const timeout = setTimeout(() => {
        setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timeout);
    });

  return (
    <>
        {isLoading && (
            <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-grow text-primary" role="status" />
            </div>
        )}

        <div>
                {/* Topbar Start */}
                <div className="container-fluid bg-dark text-white-50 py-2 px-0 d-none d-lg-block">
                    <div className="row gx-0 align-items-center">
                    <div className="col-lg-7 px-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center me-4">
                            <small className="fa fa-phone-alt me-2" />
                            <small>020 2033422 / 0726 901994</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center me-4">
                            <small className="far fa-envelope-open me-2" />
                            <small>info@syncinsuranceagency.com</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center me-4">
                            <small className="fa fa-map-marker-alt me-2" />
                            <small>KU-Plaza- 6th flr, haile selassie avenue</small>
                        </div>
                    </div>
                    <div className="col-lg-5 px-5 text-end">
                        <div className="h-100 d-inline-flex align-items-center">
                        <NavLink className="text-white-50 ms-4" to=""><i className="fab fa-facebook-f" /></NavLink>
                        <NavLink className="text-white-50 ms-4" to=""><i className="fab fa-twitter" /></NavLink>
                        <NavLink className="text-white-50 ms-4" to=""><i className="fab fa-linkedin-in" /></NavLink>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Topbar End */}
                {/* Navbar Start */}
                <Element name='navigation' className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
                    <NavLink to="/" className="navbar-brand d-flex align-items-center">
                        <h1 className="m-0">
                            <img className="img-fluid me-3" src="SYNC-INSURANCE-AGENCY.png" alt />
                        </h1>
                    </NavLink>
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav mx-auto bg-light rounded pe-4 py-3 py-lg-0">
                        <NavLink to="/" className="nav-item nav-link active">Home</NavLink>
                        <Link to="/#about" className="nav-item nav-link" onClick={scrollToAbout}>About Us</Link>
                        <div className="nav-item dropdown">
                            <NavLink to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Personal Insurance</NavLink>
                            <div className="dropdown-menu bg-light border-0 m-0">
                                <NavLink to="/MotorInsurance" className="dropdown-item">Motor Insurance</NavLink>
                                <NavLink to="/HomeInsurance" className="dropdown-item">Home Insurance</NavLink>
                                <NavLink to="/PersonalAccidentInsurance" className="dropdown-item">Personal Accident</NavLink>
                                <NavLink to="/TravelInsurance" className="dropdown-item">Travel Insurance</NavLink>
                                <NavLink to="/MedicalInsurance" className="dropdown-item">Medical Insurance</NavLink>
                                <NavLink to="/LifeInsurance" className="dropdown-item">Life Insurance</NavLink>
                                <NavLink to="/EducationInsurance" className="dropdown-item">Education Insurance</NavLink>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <NavLink to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Business Insurance</NavLink>
                            <div className="dropdown-menu bg-light border-0 m-0">
                                <NavLink to="/GroupPersonalAccident" className="dropdown-item">Group Personal Accident Accident</NavLink>
                                <NavLink to="/FireAndRelatedPerils" className="dropdown-item">Fire & Related Perils</NavLink>
                                <NavLink to="/ProfessionalIndemnityInsurance" className="dropdown-item">Professional Indemnity Insurance</NavLink>
                                <NavLink to="/WibaInsurance" className="dropdown-item">WIBA Insurance</NavLink>
                                <NavLink to="/SmeMedicalCover" className="dropdown-item">Corporate / SME Medical cover</NavLink>
                                <NavLink to="/GroupLifeInsurance" className="dropdown-item">Group Life Insurance</NavLink>
                                <NavLink to="/BurglaryInsurance" className="dropdown-item">Burglary Insurance</NavLink>
                            </div>
                        </div>
                        <NavLink to="/ContactUs" className="nav-item nav-link">Contact Us</NavLink>
                    </div>
                    </div>
                    <NavLink to="#get-started" className="btn btn-primary rounded px-3 d-none d-lg-block" onClick={getStarted}>Get A Quote</NavLink>
                </Element>
                {/* Navbar End */}
            </div>
    </>
  );
}

export default Header;