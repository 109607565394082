import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function MedicalInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Health / Medical Insurance</h3>
                    <p className='text-dark'>Personal Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/2151231393.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24"><title>pill-multiple</title><path d="M16.2 3.5C15.2 2.5 13.9 2 12.7 2S10.1 2.5 9.2 3.5L3.4 9.1C1.4 11.1 1.4 14.2 3.4 16.2S8.5 18.2 10.5 16.2L16.2 10.5C18.1 8.6 18.1 5.4 16.2 3.5M14.8 9.1L12 11.9L8.4 8.4L4 12.8C4 12 4.2 11.1 4.9 10.5L10.6 4.8C11.1 4.3 11.9 4 12.6 4S14.1 4.3 14.7 4.8C15.9 6.1 15.9 7.9 14.8 9.1M19.6 7.1C19.6 7.9 19.4 8.6 19.2 9.4C20.2 10.6 20.2 12.4 19.1 13.5L16.3 16.3L14.8 14.8L12 17.6C10.7 18.9 8.9 19.6 7.2 19.6C7.4 19.9 7.6 20.2 7.9 20.5C9.9 22.5 13 22.5 15 20.5L20.7 14.8C22.7 12.8 22.7 9.7 20.7 7.7C20.2 7.5 19.9 7.3 19.6 7.1Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">TAILORED MEDICAL INSURANCE SOLUTIONS TO MEET YOUR NEEDS</h1>
                                <p>Being the best medical insurance Kenyan company, we offer affordable medical insurance plans for health-related issues and injuries. Good family insurance helps you with expensive treatments, post-treatment appointments, charges for medicines, etc. We provide family medical insurance to keep you away from the serious financial drain and help you to prepare for the worst of health emergencies.</p>
                                <p className="fs-5 text-primary mb-4">Importance of Medical Insurance</p>
                                <p>Being one of the best medical insurance companies in Kenya, we offer medical insurance benefits of reimbursement of medical expenses in case of a medical emergency.</p>
                                <ul>
                                    <li>Provides cashless treatment</li>
                                    <li>Covers for pre and post hospitalization charges</li>
                                    <li>Covers the transportation charges</li>
                                    <li>No Claim Bonus (NCB) will be paid to the policyholder if no claim is filed for any treatment in the previous years</li>
                                    <li>Provide options for free medical checkups</li>
                                    <li>Covers for room expenses</li>
                                    <li>Tax benefits</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-12 facts-text px-0 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="container py-4 my-5">
                                <h1 className="text-white text-center mb-2">Different Types Of Medical Insurances We Provide</h1>
                                <div className='d-flex justify-content-center my-2 mb-4'><hr className='w-25'/></div>
                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Individual health insurance</h5>
                                        <p className="text-light mb-4">The medical insurance policy covers the health-related expenses and hospitalization charges of the policyholder. The premium is charged according to the age of the insured person, medical history, and the respective sum insured under this scheme. Since the plan is comprehensive coverage, the premium is slightly higher.</p>
                                    
                                        <h5 className="fw-bold text-secondary mb-2">Family medical insurance plan</h5>
                                        <p className="text-light mb-4">Under this family insurance, the policyholder can include other family members having multiple illnesses under a single cover. The family insurance includes a fixed amount for the members of the family that can be used by all members or by a single person in the family.</p>
                                    
                                        <h5 className="fw-bold text-secondary mb-2">Maternity medical insurance</h5>
                                        <p className="text-light mb-4">Our medical insurance covers costs for pre and postnatal care, child delivery expenses, ambulance costs, etc.</p>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <h5 className="fw-bold text-secondary mb-2">Senior citizen medical insurance plan</h5>
                                        <p className="text-light mb-4">As we are one of the leading medical insurance companies in Kenya, our policy offers protection and financial support for senior citizens or individuals over 60 years of age regarding health issues.</p>
                                    
                                        <h5 className="fw-bold text-secondary mb-2">Surgery and critical illness medical insurance</h5>
                                        <p className="text-light mb-4">Our medical insurance provides support for the treatment charges against critical illness such as cancer, heart attack, kidney failure, paralysis, etc.</p>
                                        
                                        <h5 className="fw-bold text-secondary mb-2">Personal accident plan</h5>
                                        <p className="text-light mb-4">As we are the best medical insurance Kenyan company, we also offer medical insurance covers for hospitalization expenses regarding accidents.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gradient-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto" style={{maxWidth: 800}}>
                            <h3 className="display-6 mb-3">Best Health Insurance Company in Kenya</h3>
                            <p className='mb-4'>Sync Insurance Agency is one of the best health insurance companies in Kenya offering coverage against medical expenses, critical illness, cashless claim benefit, tax benefits, etc. The major advantage of having health insurance in Kenya is to have good medical care during any emergency situations without any burden on your financial situation.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item rounded bg-white text-center h-100 p-5">
                                    <p className="mb-3">As a top Health insurance company in Kenya, we offer perfect health insurance policies that offer coverage against life-threatening diseases like bone marrow transplant, stroke, loss of limbs, etc. Once the illness is diagnosed from your policy, you will be given a huge amount as per your health insurance policy. You can use this amount for your daily expenses, hospital charges, medical charges, ambulance charges, treatment costs, and any other financial needs. JIA is a top health insurance company in Kenya which lets you focus more on the recovery of the patient rather than worrying about the hospital expenses, etc.</p>
                                    <p className="mb-3">Peace of mind comes from knowing you and your family are covered and protected by the best health insurance cover. We offer various medical plans to cover inpatient and outpatient from reputable medical insurance providers to suit your need.</p>
                                    <div className='row justify-content-center mt-5'>
                                        <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default MedicalInsurance;