import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

function MotorInsurance() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h3 className="display-6 animated slideInDown mb-4">Best Motor Insurance in Kenya</h3>
                    <p className='text-dark'>Personal Insurance</p>
                </div>
            </div>
            
            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative overflow-hidden rounded ps-5 pt-5 h-100" style={{minHeight: 400}}>
                                <img className="position-absolute w-100 h-100" src="img/sync-motor-vehicle-insurance.jpg" alt style={{objectFit: 'cover'}} />
                                <div className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3" style={{width: 200, height: 200}}>
                                    <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="0 0 24 24">
                                            <title>car-multiple</title>
                                            <path d="M8,11L9.5,6.5H18.5L20,11M18.5,16A1.5,1.5 0 0,1 17,14.5A1.5,1.5 0 0,1 18.5,13A1.5,1.5 0 0,1 20,14.5A1.5,1.5 0 0,1 18.5,16M9.5,16A1.5,1.5 0 0,1 8,14.5A1.5,1.5 0 0,1 9.5,13A1.5,1.5 0 0,1 11,14.5A1.5,1.5 0 0,1 9.5,16M19.92,6C19.71,5.4 19.14,5 18.5,5H9.5C8.86,5 8.29,5.4 8.08,6L6,12V20A1,1 0 0,0 7,21H8A1,1 0 0,0 9,20V19H19V20A1,1 0 0,0 20,21H21A1,1 0 0,0 22,20V12L19.92,6M14.92,3C14.71,2.4 14.14,2 13.5,2H4.5C3.86,2 3.29,2.4 3.08,3L1,9V17A1,1 0 0,0 2,18H3A1,1 0 0,0 4,17V12.91C3.22,12.63 2.82,11.77 3.1,11C3.32,10.4 3.87,10 4.5,10H4.57L5.27,8H3L4.5,3.5H15.09L14.92,3Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <h1 className="display-6 mb-5">
                                BEST MOTOR INSURANCE COVER TO PROTECT YOUR VEHICLE
                                </h1>
                                <p>We are the best car insurance Kenyan company offering customized motor insurance policies to safeguard against accidental damages, vehicle theft, and safety against third party legal liability for bodily injury and property damage. We offer easy, transparent and quick processes with no hidden conditions.</p>
                                <p className="fs-5 text-primary mb-4">Major Features of Motor Insurance:</p>
                                <ul>
                                    <li>Covers for man made activities such as burglary, theft, damage due to accidents, etc.</li>
                                    <li>Covers for CNG/LPG fuel kit</li>
                                    <li>Motor insurance policy against natural calamities such as flood, storm, earthquake, landslide, etc.</li>
                                    <li>Offers stress free travel</li>
                                    <li>Easy renewal and maintenance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid overflow-hidden my-5 px-lg-0">
                <div className="container facts px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 facts-text facts-text-motor wow fadeIn" data-wow-delay="0.1s">
                            <div className="h-100 px-4 ps-lg-0">
                                <h1 className="text-white mb-4">Key Factors Affecting Car Insurance Policy</h1>
                                <p className="text-light mb-5">There are several elements that affect the motor insurance premiums that you must be aware of to make necessary decisions</p>
                                <div className='row justify-content-start'>
                                    <div className='col-auto'><NavLink to="/ContactUs" className="btn btn-secondary py-3 px-5">Get A Quote</NavLink></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 facts-counter facts-counter-motor wow fadeIn" data-wow-delay="0.5s">
                            <div className="h-100 px-4 pe-lg-0">
                                <div className="row g-2">
                                    <div className="col-sm-12">
                                        <h5 className="fw-bold text-primary mb-1">Cover type</h5>
                                        <p className="text-primary mb-1">A comprehensive policy that covers both insured vehicles and also a third-party would attach higher premiums as against its third-party counterpart.</p>
                                    </div>
                                    <div className="col-sm-12">
                                        <h5 className="fw-bold text-primary mb-1">Safety features in the vehicle</h5>
                                        <p className="text-primary mb-1">As we are the best car insurance in Kenya, if your vehicle is equipped with additional safety features such as a GPS tracking system, anti-theft devices, etc., we consider those efforts and the premium changes accordingly.</p>
                                    </div>
                                    <div className="col-sm-12">
                                        <h5 className="fw-bold text-primary mb-1">Geographical location of your residence</h5>
                                        <p className="text-primary mb-1">One of the critical factors that affect is the area of residence. The chances of accidents and vandalism would occur more in metropolitan cities.</p>
                                    </div>
                                    <div className="col-sm-12">
                                        <h5 className="fw-bold text-primary mb-1">No Claim Bonus</h5>
                                        <p className="text-primary mb-0">It is a reward for or the insured person for not making any claim in the previous years.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto" style={{maxWidth: 600}}>
                        <h1 className="display-6 mb-5">
                            Types Of Motor Vehicle Insurance Policies We Offer
                        </h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded h-100 p-5">
                                <h4 className="mb-2 text-primery">Comprehensive</h4>
                                <p className="mb-0">We offer the best comprehensive car insurance in Kenya that includes cover for repair and replacement of the insured vehicle, accidents, theft or fire, floods, accidental third party property damages, and also include any legal liability of the insured. We offer full-fledged motor insurance cover that makes the policyholder stress-free.</p>
                            </div>
                        </div>
                   
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded h-100 p-5">
                                <h4 className="mb-2 text-primery">Third Party Only</h4>
                                <p className="mb-0">Being the best car insurance Kenya, we offer covers costs and damages to the third party property legal liabilities and bodily injuries. Being the best car insurance in Kenya, we provide the best and affordable basic coverage that is being a mandatory requirement for your car.</p>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded h-100 p-5">
                                <h4 className="mb-2 text-primery">Extension Benefits</h4>
                                <p className="mb-0">Our top car insurance Kenyan company recommends two enticing benefits on top of the basic car policy. They are the excess protector for their own damage and the political violence & terrorism (PVT) cover. If the customers don’t have an excess protector extension, they may be forced to pay high charges for the excess on the motor insurance policy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default MotorInsurance;